import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {TaskSupervisionRow} from "@services/dto/taskSupervision";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {ProjectRow} from "@services/dto/project";
import {SelectFiling} from "@components/SelectFiling";
import {SelectTaskSupervision} from "@components/SelectTaskSupervision";
import {BLACK_LIST} from "@/config";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {SelectDict} from "@components/SelectDict";
import {logger} from "@common/utils";

export interface PropsType {
  row: TaskSupervisionRow
  isView?: boolean
  isBatchUpdate?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const [SelectCooperativeUnitMode] = useState<'multiple' | 'tags'>()
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()
  const [tsTaskDisable, setTsTaskDisable] = useState<boolean>()
  const [dateDisable, setDateDisable] = useState<boolean>()

  useEffect(() => {
    form.resetFields()
    const taskSupervisionRow = {...props.row}

    if (typeof taskSupervisionRow.tsDate === 'string' && taskSupervisionRow.tsDate !== '') {
      taskSupervisionRow.tsDate = dayjs(taskSupervisionRow.tsDate)
    }
    if (typeof taskSupervisionRow.tsStartComp === 'string' && taskSupervisionRow.tsStartComp !== '') {
      taskSupervisionRow.tsStartComp = dayjs(taskSupervisionRow.tsStartComp)
    }
    if (typeof taskSupervisionRow.tsPlanComp === 'string' && taskSupervisionRow.tsPlanComp !== '') {
      taskSupervisionRow.tsPlanComp = dayjs(taskSupervisionRow.tsPlanComp)
    }
    if (typeof taskSupervisionRow.tsStartDate === 'string' && taskSupervisionRow.tsStartDate !== '') {
      taskSupervisionRow.tsStartDate = dayjs(taskSupervisionRow.tsStartDate)
    }
    if (typeof taskSupervisionRow.tsEndDate === 'string' && taskSupervisionRow.tsEndDate !== '') {
      taskSupervisionRow.tsEndDate = dayjs(taskSupervisionRow.tsEndDate)
    }
    if (typeof taskSupervisionRow.tsTask === 'string' && taskSupervisionRow.tsTask !== '') {
      taskSupervisionRow.tsTask = taskSupervisionRow.tsTask.split(",")
    } else {
      taskSupervisionRow.tsTask = []
    }
    if (typeof taskSupervisionRow.wnUserNames === 'string' && taskSupervisionRow.wnUserNames !== '') {
      taskSupervisionRow.wnUserNames = taskSupervisionRow.wnUserNames.split(",")
    } else {
      taskSupervisionRow.wnUserNames = []
    }
    setResponsibilityProject(taskSupervisionRow.responsibilityProject)
    setCooperationUnit(taskSupervisionRow.cooperationUnit)

    form.setFieldsValue(taskSupervisionRow);
    calculateAndSetTsPlanComp()
    calculateAndSetTsPracticalProject()
    judgeTsTaskDisable()
    judgeDateDisable()
  }, [props.row])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  /**
   * 关联工作日程
   * @param v
   * @param options
   */
  /*const handleChangeWorkSchedule = (v, options) => {
    const workSchedule = options.find(o => o.id === v)
    form.setFieldsValue({
      workScheduleCode: workSchedule?.wsCode,
      tsStartComp: dayjs(workSchedule?.wsWorkPlanStartDate),
      tsPlanComp: dayjs(workSchedule?.wsWorkPlanCompletionDate),
      tsPlanProject: workSchedule?.wsWorkPlanDuration,
      responsibilityProject: workSchedule?.responsibilityProject,
      cooperationUnit: workSchedule?.cooperationUnit,
      personResponsible: workSchedule?.personResponsible,
      tsFile: workSchedule?.wsFile,
      tsFileCode: workSchedule?.wsFileCode,
    })
  }*/

  /**
   * 计算并设置计划完成日期
   */
  const calculateAndSetTsPlanComp = () => {
    const tsStartComp = form.getFieldValue("tsStartComp"); // 计划开始日期
    const tsPlanProject = form.getFieldValue("tsPlanProject"); // 计划工期
    const tsPlanComp = form.getFieldValue("tsPlanComp"); // 计划完成日期
    const tsLevel = form.getFieldValue("tsLevel");
    if (tsLevel) {
      if (['1'].includes(tsLevel) && typeof tsStartComp === 'object' && tsStartComp && typeof tsPlanComp === 'object' && tsPlanComp) {
        const tsPlanProject = tsPlanComp.diff(tsStartComp, 'day') + 1
        form.setFieldsValue({
          tsPlanProject: tsPlanProject,
        });
      } else if (['2'].includes(tsLevel) && typeof tsStartComp === 'object' && tsStartComp && tsPlanProject) {
        const tsPlanComp = tsStartComp.add(tsPlanProject - 1, 'day');
        form.setFieldsValue({
          tsPlanComp: tsPlanComp,
        });
      }

    }
  };

  /**
   * 计算并设置实际工期
   */
  const calculateAndSetTsPracticalProject = () => {
    const tsStartDate = form.getFieldValue("tsStartDate");
    const tsEndDate = form.getFieldValue("tsEndDate");
    if (typeof tsStartDate === 'object' && tsStartDate && typeof tsEndDate === 'object' && tsEndDate) {
      const tsPracticalProject = tsEndDate.diff(tsStartDate, 'day') + 1
      form.setFieldsValue({
        tsPracticalProject: tsPracticalProject,
      });
    } else {
      form.setFieldsValue({
        tsPracticalProject: 0,
      });
    }
  };

  /**
   * 判断前置任务的禁选
   */
  const judgeTsTaskDisable = () => {
    const tsLevel = form.getFieldValue("tsLevel");
    setTsTaskDisable(tsLevel !== '2')
  };

  /**
   * 判断工期和计划开始日期的禁选
   */
  const judgeDateDisable = () => {
    const tsLevel = form.getFieldValue("tsLevel");
    const tsTask = form.getFieldValue("tsTask");
    setDateDisable(tsLevel !== '2' || (Array.isArray(tsTask) && tsTask.length > 0))
  };

  /**
   * 关联上级任务
   * @param v
   * @param options
   */
  const handleTsTaskPid = (v: string, options: TaskSupervisionRow[]) => {
    const row = options.find(o => v === o.id && !BLACK_LIST.includes(v))
    if (row) {
      // 根据上级任务的级别设置当前任务的级别
      const currentLevel = determineTaskLevel(row.tsLevel)
      form.setFieldsValue({
        tsTaskPidCode: row?.tsCode,
        tsLevel: currentLevel,
      })
      if (['1'].includes(currentLevel)) {
        form.setFieldsValue({
          tsPlanProject: undefined,
          tsStartComp: undefined,
        })
      }
      if (['2'].includes(currentLevel)) {
        logger.log('选中的二级任务', row)
        form.setFieldsValue({
          responsibilityProject: row.responsibilityProject,
          cooperationUnit: row.cooperationUnit,
          tsFile: row.tsFile,
          tsFileCode: row.tsFileCode,
        })
      }
    } else {
      form.setFieldsValue({
        responsibilityProject: undefined,
        cooperationUnit: undefined,
        tsTaskPidCode: undefined,
        tsLevel: '1', // 什么都不选就是一级
        tsPlanProject: undefined,
        tsStartComp: undefined,
      })
    }
    judgeTsTaskDisable()
    judgeDateDisable()
  }

  /**
   * 确定当前任务的级别
   * @param parentLevel 上级任务的级别
   * @return 当前任务的级别
   */
  const determineTaskLevel = (parentLevel: string) => {
    // 假设任务级别是字符串形式，例如 '一级', '二级', '三级'
    const levels = ['1', '2', '3'];
    // 找到上级任务的索引
    const parentIndex = levels.indexOf(parentLevel);
    // 如果上级任务是一级，则当前任务是二级；如果是二级，则当前任务是三级
    return parentIndex === 0 ? levels[1] : parentIndex === 1 ? levels[2] : levels[0];
  };

  /**
   * 关联前置任务onChange
   * @param v
   * @param options
   */
  const handleTsTaskChange = (v: string[], options: TaskSupervisionRow[]) => {
    const ids = options
      .filter(o => v.includes(o.id))
      .filter(o => !BLACK_LIST.includes(o.id))
      .map(o => o.tsCode)
    form.setFieldsValue({
      tsTaskCode: ids.join(",")
    })
    judgeDateDisable()
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView}
      initialValues={{
        tsTask: ['N/A'],
        tsTaskPid: 'N/A',
      }}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="workScheduleCode">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="tsTaskCode">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="tsTaskPidCode">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="tsDate"
            rules={[{required: false, message: '请输入制单日期!'}]}>
            <DatePicker
              disabled
              placeholder={"请选择制单日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="tsCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input placeholder="请输入制单编号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="工作摘要"
            name="tsDesc"
            rules={[{required: false, message: '请输入工作摘要!'}]}>
            <Input.TextArea
              placeholder="请输入工作摘要"
              autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="任务级别"
            name="tsLevel"
            initialValue='1'
            rules={[{required: false, message: '请选择任务级别!'}]}>
            <SelectDict
              disabled
              fieldNames={{value: 'value', label: 'key'}}
              defaultItems={[]}
              code={'dict_task_supervision_leve'}
              placeholder={'请选择任务级别'}/>
          </Form.Item>
        </Col>
        { /*<Col span={24}>
          <Form.Item
            label="关联工作日程"
            name="workScheduleId"
            rules={[{required: false, message: '请选择关联工作日程!'}]}>
            <SelectWorkSchedule
              onChange={handleChangeWorkSchedule}
              placeholder={"请选择关联工作日程"}
              allowClear/>
          </Form.Item>
        </Col>*/ }
        <Col span={24}>
          <Form.Item
            label="关联上级任务"
            name="tsTaskPid"
            rules={[{required: !props.isBatchUpdate, message: '请选择关联上级任务!'}]}>
            <SelectTaskSupervision
              defaultItems={[{id: 'N/A', value: 'N/A'}]}
              tsLevel={'1'}
              onChange={handleTsTaskPid}
              placeholder={"请选择关联上级任务"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联前置任务"
            name="tsTask"
            rules={[{required: false, message: '请选择关联前置任务!'}]}>
            <SelectTaskSupervision
              tsLevel={'2'}
              onChange={handleTsTaskChange}
              disabled={tsTaskDisable || props.isView}
              placeholder={"请选择关联前置任务"}
              mode={"multiple"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="计划工期"
            name="tsPlanProject"
            rules={[{required: false, message: '请输入计划工期!'}]}>
            <InputNumber
              disabled
              onChange={() => calculateAndSetTsPlanComp()}
              precision={0}
              placeholder="请输入计划工期"
              className={'width-100-percentage'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="计划开始日期"
            name="tsStartComp"
            rules={[{required: false, message: '请选择计划开始日期!'}]}>
            <DatePicker
              disabled={dateDisable || props.isView}
              placeholder={"请选择计划开始日期"}
              onChange={() => calculateAndSetTsPlanComp()}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="计划完成日期"
            name="tsPlanComp"
            rules={[{required: false, message: '请选择计划完成日期!'}]}>
            <DatePicker
              disabled
              placeholder={"请选择计划完成日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="实际工期"
            name="tsPracticalProject"
            rules={[{required: false, message: '请输入实际工期!'}]}>
            <InputNumber disabled precision={0} placeholder="请输入实际工期" className={'width-100-percentage'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="实际开始日期"
            name="tsStartDate"
            rules={[{required: false, message: '请选择实际开始日期!'}]}>
            <DatePicker
              disabled
              placeholder={"请选择实际开始日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="实际完成日期"
            name="tsEndDate"
            rules={[{required: false, message: '请输入实际完成日期!'}]}>
            <DatePicker
              disabled
              placeholder={"请选择日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="任务状态"
            name="statusNotice"
            rules={[{required: false, message: '请输入任务状态!'}]}>
            <Input placeholder="请输入任务状态" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"}
              onChange={handleProjectChange}
              disabled={!tsTaskDisable}
              allowClear
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              allowClear
              mode={SelectCooperativeUnitMode}
              disabled={!tsTaskDisable}
              onChange={(value) => setCooperationUnit(value)}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        {
          props.row.id && <Col span={24}>
            <Form.Item
              label="存档批号"
              name="tsFile"
              rules={[{required: false, message: '请选择存档批号!'}]}>
              <SelectFiling
                responsibilityProject={responsibilityProject}
                isHideClose
                isHidePlus
                cooperationUnit={cooperationUnit}
                filingMode={"multiple"}
                inputDisabled
                placeholder={"请选择存档批号"}
                allowClear/>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              disabled={props.isView || props.row?.tsLevel === '2'}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              disabled
              mode={'multiple'}
              className={"width-100-percentage"}
              placeholder={"请选择办理人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="参与人员"
            name="wnUserNames"
            rules={[{required: false, message: '请选择参与人员!'}]}>
            <SelectPersonCharge
              disabled
              mode={'multiple'}
              className={"width-100-percentage"}
              placeholder={"请选择参与人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="tsStatus"
            rules={[{required: false, message: '请输入办理进度!'}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
