import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Divider, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {ProjectRow} from "@services/dto/project";
import {SelectFiling} from "@components/SelectFiling";
import {FORM_DISABLED_STATE} from "@/config";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {ProjectStructureRow} from "@services/dto/projectStructure";
import {SelectProjectStructure} from "@components/SelectProjectStructure";
import {SelectBudgetManagement} from "@components/SelectBudgetManagement";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {SelectDict} from "@components/SelectDict";

export interface PropsType {
  row: ProjectStructureRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const projectStructureRow = {...props.row}
    filingStore.setFilingFaCode(projectStructureRow.psCode)
    if (typeof projectStructureRow.psDate === 'string' && projectStructureRow.psDate !== '') {
      projectStructureRow.psDate = dayjs(projectStructureRow.psDate)
    }
    if (typeof projectStructureRow.cooperationUnit === 'string') {
      projectStructureRow.cooperationUnit = projectStructureRow.cooperationUnit !== '' ? projectStructureRow.cooperationUnit.split(',') : []
    }
    /*if (projectStructureRow.projectMilestoneStructureMiddles && projectStructureRow.projectMilestoneStructureMiddles.length > 0) {
      projectStructureRow.projectMilestoneStructureMiddles = projectStructureRow.projectMilestoneStructureMiddles.map(item => ({
        ...item,
        planFinishDate: item.planFinishDate ? dayjs(item.planFinishDate) : undefined,
        realityFinishDate: item.realityFinishDate ? dayjs(item.realityFinishDate) : undefined
      }))
    }*/
    setResponsibilityProject(projectStructureRow.responsibilityProject);
    setCooperationUnit(projectStructureRow.cooperationUnit)
    form.setFieldsValue(projectStructureRow);
  }, [props.row.id])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{
        cooperationUnit: ['N/A'],
        projectBudgetStructureMiddles: [],
        projectMilestoneStructureMiddles: []
      }}
      disabled={props.isView || FORM_DISABLED_STATE.includes(props.row.psStatus)}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="psDate"
            rules={[{required: false, message: '请选择制单日期!'}]}>
            <DatePicker disabled placeholder={"请选择制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="psCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input disabled placeholder="请输入制单编号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="项目名称"
            name="psName"
            rules={[{required: false, message: '请输入项目名称!'}]}>
            <Input placeholder="请输入项目名称"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="项目概述"
            name="psDesc"
            rules={[{required: false, message: '简要描述项目的背景、目标、范围、预期成果、资源需求、风险评估!'}]}>
            <Input.TextArea placeholder="简要描述项目的背景、目标、范围、预期成果、资源需求、风险评估" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联上级预算"
            name="psBudget"
            rules={[{required: false, message: '请选择关联上级预算!'}]}>
            <SelectBudgetManagement disabled placeholder={'请选择关联上级预算'} allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联上级项目"
            name="parentId"
            rules={[{required: false, message: '请选择关联上级项目!'}]}>
            <SelectProjectStructure disabled placeholder={"请选择关联上级项目"} allowClear/>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.List name={"projectBudgetStructureMiddles"}>
            { (fields, {add, remove}) => (
              <>
                <Divider style={{margin: '0 0 10px 0'}}/>
                { fields.map((field, index) => (
                  <Row key={field.key}>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        hidden
                        name="id">
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        hidden
                        name="structureId">
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col span={24} className={"margin-bottom-xs"}>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{span: 12}}
                            wrapperCol={{span: 12}}
                            label="预算类别"
                            name={[field.name, 'budgetCategory']}
                            rules={[{required: false, message: '请输入预算类别!'}]}>
                            <SelectDict
                              className={'width-100-percentage'}
                              allowClear
                              code={'budget_level'}
                              placeholder={'请输入预算类别'}/>
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item
                            {...field}
                            labelCol={{span: 6}}
                            wrapperCol={{span: 17}}
                            label="金额"
                            name={[field.name, 'budgetMoney']}
                            rules={[{required: false, message: '请输入金额!'}]}>
                            <InputNumber precision={2} placeholder="请输入金额" className={"width-100-percentage"}/>
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Button size={'middle'} icon={<MinusCircleOutlined/>} onClick={() => remove(index)}></Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )) }
                <Col span={18} offset={3} className={"margin-bottom-xs"}>
                  <Form.Item wrapperCol={{span: 20, offset: 3}} className={"text-align-center"}>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                        新增预算金额
                    </Button>
                  </Form.Item>
                </Col>
                <Divider className={'margin-none'}/>
              </>
            ) }
          </Form.List>
        </Col>

        <Col span={24}>
          <Form.List name={"projectMilestoneStructureMiddles"}>
            { (fields, {add, remove}) => (
              <>
                <Divider style={{margin: '0 0 10px 0'}}/>
                { fields.map((field, index) => (
                  <Row key={field.key}>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        hidden
                        name="id">
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        hidden
                        name="structureId">
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col span={24} className={"margin-bottom-xs"}>
                      <Row>
                        <Col span={21}>
                          <Form.Item
                            {...field}
                            labelCol={{span: 7}}
                            wrapperCol={{span: 16}}
                            label="项目里程碑"
                            name={[field.name, 'milestone']}
                            rules={[{required: false, message: '请输入项目里程碑!'}]}>
                            <Input.TextArea placeholder="请输入项目里程碑" autoSize={{minRows: 3, maxRows: 9}}/>
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Button size={'middle'} icon={<MinusCircleOutlined/>} onClick={() => remove(index)}></Button>
                        </Col>
                      </Row>
                    </Col>
                    { /*<Col span={24} className={"margin-bottom-xs"}>
                      <Form.Item
                        {...field}
                        label="计划完成日期"
                        name={[field.name, 'planFinishDate']}
                        rules={[{required: false, message: '请选择计划完成日期!'}]}>
                        <DatePicker placeholder={"请选择计划完成日期"} className={"width-100-percentage"}/>
                      </Form.Item>
                    </Col>
                    <Col span={24} className={"margin-bottom-xs"}>
                      <Form.Item
                        {...field}
                        label="实际完成日期"
                        name={[field.name, 'realityFinishDate']}
                        rules={[{required: false, message: '请选择实际完成日期!'}]}>
                        <DatePicker placeholder={"请选择实际完成日期"} className={"width-100-percentage"}/>
                      </Form.Item>
                    </Col>*/ }
                  </Row>
                )) }
                <Col span={18} offset={3} className={"margin-bottom-xs"}>
                  <Form.Item wrapperCol={{span: 20, offset: 3}} className={"text-align-center"}>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                        新增项目里程碑
                    </Button>
                  </Form.Item>
                </Col>
                <Divider className={'margin-none'}/>
              </>
            ) }
          </Form.List>
        </Col>

        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"} allowClear onChange={handleProjectChange}
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              allowClear
              mode={'tags'}
              onChange={(value) => setCooperationUnit(value)}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="psFile"
            rules={[{required: false, message: '请选择存档批号!'}]}>
            <SelectFiling
              isHidePlus
              isHideClose
              responsibilityProject={responsibilityProject}
              cooperationUnit={cooperationUnit}
              placeholder={"请选择存档批号"}
              inputDisabled
              fieldNames={{value: "id", label: "faBatchNumber"}}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择办理人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="参与人员"
            name="wnUserNames"
            rules={[{required: false, message: '请输入参与人员!'}]}>
            <Input placeholder="请输入参与人员" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="psStatus"
            rules={[{required: false, message: '请选择办理进度',}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
