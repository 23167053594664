import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {
  DEFAULT_MODAL_WIDTH,
  FORM_CANCEL_TEXT,
  FORM_HANDLE_SUBMIT_TEXT_STATE,
  FORM_HANDLE_TEXT,
  FORM_HANDLE_TEXT_STATE,
  FORM_SAVE_TEXT,
  FORM_SAVE_TEXT_STATE,
  SUPER_ROLE
} from "@/config";
import {Button, Drawer} from "antd";
import {allStores} from "@/stores";
import {btnItemType, FormAuthOptions} from "src/components/CustomAuthOptions";
import {RoleAndMenuType} from "@services/dto/roleManage";

export interface PropsType {
  children?: React.ReactNode
  extra?: React.ReactNode
  handleCancel?: () => void
  extraDisabled?: boolean
  isView?: boolean
  extraOnClick?: () => void
  handleOk?: (open: boolean) => void
  isModalVisible?: boolean
  title?: string
  userId?: string
  zIndex?: number;
  confirmLoading?: boolean
  submitterId?: string
  status?: string
  item?: btnItemType[]
  push?: boolean | { distance: string | number }
  isTransact?: boolean
}

export const CustomForm = observer((props: PropsType) => {

  const {securityStore} = allStores
  const currentUserId = securityStore.getLoggedUser()._id
  const [role, setRole] = useState<string[]>([])

  useEffect(() => {
    const roleAndMenus: RoleAndMenuType = JSON.parse(localStorage.getItem('role-and-menus'))
    setRole(roleAndMenus.roleMS?.map(o => o.name) || [])
  }, []);

  return (

    <Drawer
      title={<div className="text-align-center">{ props.title || '表单' }</div>}
      width={DEFAULT_MODAL_WIDTH}
      open={props.isModalVisible}
      destroyOnClose
      zIndex={props.zIndex}
      push={props.push}
      extra={<FormAuthOptions disabled={props.extraDisabled} onClick={props.extraOnClick}
        items={props.item}></FormAuthOptions>}
      onClose={props.handleCancel}>

      { props.children }

      <div className={"drawer-bottom d-flex df-jcsb"}>
        <div>
          { !props.isTransact &&
            <Button
              type="primary"
              className={"margin-right-xs"}
              onClick={() => props.handleOk && props.handleOk(true)}
              disabled={
                props.isView ||
                  !(
                    ([props.userId].every(id => id === currentUserId) && FORM_HANDLE_TEXT_STATE.includes(props.status)) ||
                      ([props.submitterId].every(id => id === currentUserId) && FORM_HANDLE_SUBMIT_TEXT_STATE.includes(props.status)) ||
                      ([props.userId, props.submitterId].every(id => id === currentUserId) && [...FORM_HANDLE_SUBMIT_TEXT_STATE, ...FORM_HANDLE_TEXT_STATE].includes(props.status)) ||
                      (role.some(o => SUPER_ROLE.includes(o)) && [...FORM_HANDLE_TEXT_STATE, ...FORM_HANDLE_TEXT_STATE, ...FORM_HANDLE_TEXT_STATE].includes(props.status))
                  )
              }
              loading={props.confirmLoading}>
              { FORM_HANDLE_TEXT }
            </Button>
          }
        </div>
        <div>
          <Button onClick={props.handleCancel}>{ FORM_CANCEL_TEXT }</Button>
          <Button
            type="primary"
            className={"margin-left-xs"}
            onClick={() => props.handleOk && props.handleOk(false)}
            disabled={
              props.isView ||
              !(
                ([props.userId].every(id => id === currentUserId) && FORM_SAVE_TEXT_STATE.includes(props.status)) ||
                (role.some(o => SUPER_ROLE.includes(o)) && FORM_SAVE_TEXT_STATE.includes(props.status))
              )
            }
            loading={props.confirmLoading}>
            { FORM_SAVE_TEXT }
          </Button>
        </div>
      </div>
    </Drawer>
  )
})
